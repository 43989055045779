import React from 'react'



function Lights() {


  return (
    <>
    <directionalLight color="#F5C400" position={[40, 150, -100]}  intensity={20} />
    </>
  )
}


export { Lights }
